@font-face {
  font-family: 'iconsmind';
  src: url("../fonts/iconsmind.eot%3Fu2jiyw");
  src: url("../fonts/iconsmind.eot%3Fu2jiyw") format("embedded-opentype"), url("../fonts/iconsmind.ttf%3Fu2jiyw") format("truetype"), url("../fonts/iconsmind.woff%3Fu2jiyw") format("woff"), url("../fonts/iconsmind.svg%3Fu2jiyw") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'iconsmind';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-Add-File:before {
  content: "\e605"; }

.icon-Air-Balloon:before {
  content: "\e614"; }

.icon-Bacteria:before {
  content: "\e681"; }

.icon-Beer-Glass:before {
  content: "\e6a9"; }

.icon-Bicycle:before {
  content: "\e6b5"; }

.icon-Brush:before {
  content: "\e6f7"; }

.icon-Business-ManWoman:before {
  content: "\e6ff"; }

.icon-Checked-User:before {
  content: "\e73f"; }

.icon-Checkout-Bag:before {
  content: "\e741"; }

.icon-Clock-Back:before {
  content: "\e76f"; }

.icon-Cloud:before {
  content: "\e78a"; }

.icon-Code-Window:before {
  content: "\e792"; }

.icon-Coding:before {
  content: "\e793"; }

.icon-Coffee-Machine:before {
  content: "\e796"; }

.icon-Compass-Rose:before {
  content: "\e7aa"; }

.icon-Credit-Card2:before {
  content: "\e7c3"; }

.icon-Cursor-Click2:before {
  content: "\e7d1"; }

.icon-Data-Storage:before {
  content: "\e7f3"; }

.icon-Duplicate-Window:before {
  content: "\e841"; }

.icon-Email:before {
  content: "\e854"; }

.icon-Family-Sign:before {
  content: "\e883"; }

.icon-Finger-Print:before {
  content: "\e8c4"; }

.icon-Fingerprint-2:before {
  content: "\e8c6"; }

.icon-Fingerprint:before {
  content: "\e8c7"; }

.icon-Hand-Touch:before {
  content: "\e98e"; }

.icon-Handshake:before {
  content: "\e993"; }

.icon-Hospital-2:before {
  content: "\e9c4"; }

.icon-Hour:before {
  content: "\e9c9"; }

.icon-Laptop-3:before {
  content: "\ea13"; }

.icon-Laptop-Phone:before {
  content: "\ea14"; }

.icon-Laughing:before {
  content: "\ea1b"; }

.icon-Life-Jacket:before {
  content: "\ea3d"; }

.icon-Life-Safer:before {
  content: "\ea3e"; }

.icon-Love-User:before {
  content: "\ea64"; }

.icon-Mail-3:before {
  content: "\ea71"; }

.icon-Medical-Sign:before {
  content: "\eaa8"; }

.icon-Nurse:before {
  content: "\eb10"; }

.icon-Pantone:before {
  content: "\eb39"; }

.icon-Pen:before {
  content: "\eb4d"; }

.icon-Phone-Wifi:before {
  content: "\eb5e"; }

.icon-Post-Mail2:before {
  content: "\eb94"; }

.icon-Preview:before {
  content: "\eba5"; }

.icon-Road-3:before {
  content: "\ebfe"; }

.icon-Sidebar-Window:before {
  content: "\ec65"; }

.icon-Sport-Mode:before {
  content: "\ecbc"; }

.icon-Target-Market:before {
  content: "\ed1a"; }

.icon-Text-Effect:before {
  content: "\ed34"; }

.icon-Wizard:before {
  content: "\ee08"; }
